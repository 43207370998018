<template>
  <div class="view">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :exact="true"
        :to="{ name: 'dynamicQrCode', params: {} }"
      >
        Dynamic Qr Code
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'dynamicQrCodeView', params: {} }">
        View Dynamic Qr Code
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card v-if="loaded">
      <v-card-title> View Dynamic Qr Code </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-if="dynamicQrCode.uuid">
                <td max-width="35%" class="text-subtitle-1">ID:</td>
                <td>{{ dynamicQrCode.uuid }}</td>
              </tr>

              <tr v-if="dynamicQrCode.staffId">
                <td max-width="35%" class="text-subtitle-1">Staff:</td>
                <td>{{ dynamicQrCode.staffId }}</td>
              </tr>

              <tr v-if="accessCode">
                <td max-width="35%" class="text-subtitle-1">Access Code:</td>
                <td>{{ accessCode }}</td>
              </tr>

              <tr v-if="dynamicQrCode.type">
                <td max-width="35%" class="text-subtitle-1">Business Type:</td>
                <td>{{ dynamicQrCode.businessType }}</td>
              </tr>

              <tr v-if="dynamicQrCode.type">
                <td max-width="35%" class="text-subtitle-1">Type:</td>
                <td>{{ dynamicQrCode.type }}</td>
              </tr>

              <tr v-if="dynamicQrCode.jsonData">
                <td max-width="35%" class="text-subtitle-1">Text:</td>
                <td>
                  <div class="my-2" style="white-space: pre">
                    {{ dynamicQrCode.searchText }}
                  </div>
                </td>
              </tr>
              <!--           
          <tr v-if="dynamicQrCode.jsonData">
            <td max-width="35%" class="text-subtitle-1">Data:</td>
            <td>{{ dynamicQrCode.jsonData }}</td>
          </tr> -->
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <div class="d-flex justify-space-between ma-5">
      <v-btn @click="goBack">Back</v-btn>
      <v-btn @click="edit()" color="primary">Edit</v-btn>
      <span></span>
    </div>
  </div>
</template>

<script>
import { getDynamicQrCode } from "@/services/dynamicQrCode";

export default {
  data() {
    return {
      id: "",
      dynamicQrCode: {},
      accessCode: null,
      loaded: false,
      relations: {},
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    edit() {
      if (this.dynamicQrCode.businessType == "WORKSHEET") {
        this.$router.push({
          name: "worksheetQRCodeForm",
          params: { uuid: this.dynamicQrCode.uuid },
        });
      } else {
        this.$router.push({
          name: "dynamicQrCodeEdit",
          params: { id: this.dynamicQrCode.uuid },
        });
      }
    },
    setInstance() {
      this.setDependencies().then(() => {
        getDynamicQrCode(this.id)
          .then((response) => {
            this.dynamicQrCode = response.data;
            this.getAccessCode();
            this.loaded = true;
          })
          .catch(() => {
            this.addErrorMessage("Error loading dynamicQrCode");
            this.loaded = true;
          });
      });
    },
    async setDependencies() {
      return null;
    },
    getRelationText(id, relationProperty, relationAttribute) {
      const relationList = this.relations[relationProperty];
      if (relationList) {
        const relationObj = relationList.find((r) => r.id == id);
        return relationObj[relationAttribute];
      } else {
        return "";
      }
    },
    getAccessCode(){
      this.$axios('/dynamicQrCode/access-code/'+this.dynamicQrCode.uuid)
        .then((response) => {
          this.accessCode = response.data;
        })
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.setInstance();
    this.$emit("showParent", false);
  },
};
</script>
